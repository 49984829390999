import React from "react";
import "./styles/article-figure.css";
import "./styles/article.css";
import "./styles/article-text.css";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
const PostComponent = styled.article`
  padding: 0.5in 0;

  display: grid;
  grid-template-columns: 1fr 5% [start] 15% 60% 15% [end] 5% 1fr;

  background: linear-gradient(hsl(55, 100%, 96%), white 6.55rem);
`;

function Post(props) {
  var date = props.date;
  var newdate =
    date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  return (
    <article>
      <h1>
        <a>{props.title}</a>
      </h1>
      <h2>{props.title}</h2>
      <time>{newdate}</time>
      <div> {ReactHtmlParser(props.intro)} </div>

      <figure className="size-4">
        <img src={props.imgs[0]} style={{ width: "100%" }} alt="image0" />
      </figure>
      <figcaption>
        <p>{props.captions[0]}</p>
      </figcaption>
    </article>
  );
}
export default Post;
