import React from "react";
import Firebase from "firebase";
import firebaseConfig from "./firebaseConfig";
import styled from "styled-components";
import "./styles/reset.css";
import "./styles/article.css";
import "./styles/article-share.css";
import "./styles/article-text.css";
import ReactHtmlParser from "react-html-parser";
import Post from "./Post";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      posts: [],
    };
  }

  getPosts = () => {
    const db = Firebase.firestore();
    const postsRef = db.collection("posts");

    postsRef.orderBy("timestamp", "desc").onSnapshot((snapshot) => {
      let newPosts = [];
      snapshot.docs.forEach((doc) => {
        newPosts.push(doc);
      });
      this.setState({ posts: newPosts });
    });
  };

  componentDidMount() {
    const firebaseApp =
      Firebase.apps && Firebase.apps.length > 0
        ? Firebase.apps[0]
        : Firebase.initializeApp(firebaseConfig);
    this.getPosts();
  }

  render = () => {
    const posts = this.state.posts;
    const postComponents = posts.map((doc) => (
      <Post
        date={doc.data().timestamp.toDate()}
        title={doc.data().title}
        intro={doc.data().content}
        imgs={doc.data().imgs}
        captions={doc.data().captions}
      />
    ));
    console.log("Post components: ", postComponents);

    return (
      <div className="App">
        <img
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
          src={require("./images/logo.jpg")}
          alt="logo"
        />
        {postComponents}
      </div>
    );
  };
}

export default App;
